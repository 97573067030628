// extracted by mini-css-extract-plugin
export var customServiceModal = "style-mod--custom-service-modal--1GD13";
export var modalClose = "style-mod--modal-close--2jcgN";
export var modalCloseDesktop = "style-mod--modal-close-desktop--o-_Jt";
export var modalCloseDesktopDesktop = "style-mod--modal-close-desktop-desktop--2Qzy8";
export var modalCloseDesktopResponsive = "style-mod--modal-close-desktop-responsive--2_TT2";
export var modalCloseResponsive = "style-mod--modal-close-responsive--1J_Nu";
export var modalParagraph = "style-mod--modal-paragraph--2S5A3";
export var modalTitle = "style-mod--modal-title--q2fm2";
export var customerContactHead = "style-mod--customer-contact-head--3XHeJ";
export var customerContactHeadTitle = "style-mod--customer-contact-head-title--3yiFb";
export var customerContactContent = "style-mod--customer-contact-content--16Dtd";
export var customerContactText = "style-mod--customer-contact-text--2tmtz";
export var customerContactImage = "style-mod--customer-contact-image--2v5-j";
export var desktopImage = "style-mod--desktop-image--hHUrJ";
export var mobileImage = "style-mod--mobile-image--1tsKz";
export var customerContactImageItem = "style-mod--customer-contact-image-item--27mDx";
export var customerContactTextTitle = "style-mod--customer-contact-text-title--sj8Nu";
export var customerContactInfo = "style-mod--customer-contact-info--3y7AL";
export var customerContactParagraph = "style-mod--customer-contact-paragraph--3utTB";
export var customerContactFeedbackTitle = "style-mod--customer-contact-feedback-title--3Nkm5";
export var customerContactInfoIcon = "style-mod--customer-contact-info-icon--pb-xq";
export var customerContactTel = "style-mod--customer-contact-tel--2KIRT";
export var customerContactEmail = "style-mod--customer-contact-email--3X83g";
export var customerContactFeedback = "style-mod--customer-contact-feedback--2_F-9";