import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TelImage from "assets/images/social/tel.svg";
import EmailImage from "assets/images/social/email.svg";
import CloseIcon from "assets/svgs/close-btn.svg";
import RichText from "components/rich-text";
import Image from "components/image";

import {
  customerContact, customerContactHead, customerContactContent,
  customerContactText, customerContactInfo, customerContactTel,
  customerContactEmail, customerContactFeedback, customerContactImage,
  customerContactParagraph, customerContactInfoIcon,
  customerContactImageItem, modalClose, modalParagraph,
  modalCloseDesktop, modalCloseResponsive, customServiceModal,
  mobileImage, desktopImage, modalContainer
} from "./style.mod.scss";

const CustomerContact = ({ data, children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mData = data?.modal && data.modal[0];
  if (!data || !mData) return <></>;

  return (
    <div className={customerContact}>
      <div className={modalContainer} onClick={handleShow}>
        { children }
      </div>

      <Modal show={show} onHide={handleClose} className={customServiceModal}>
        <CloseIcon className={`${modalClose} ${modalCloseDesktop}`} onClick={handleClose} />
        <Modal.Body>
          <CloseIcon className={`${modalClose} ${modalCloseResponsive}`} onClick={handleClose} />
          <div className={customerContactHead}>
            <RichText
              blocks={mData.title}
            />
          </div>
          <div className={customerContactContent}>
            <div className={customerContactText}>
              <div className={customerContactInfo}>
                <div className={customerContactTel}>
                  <img className={customerContactInfoIcon} src={TelImage} />
                  {
                    mData?.phone?.split("\n").map((_elm, index) => (
                      <p key={index} className={`${modalParagraph} ${customerContactParagraph}`} >{ _elm }</p>
                    ))
                  }
                </div>
                <div className={customerContactEmail} >
                  <img className={customerContactInfoIcon} src={EmailImage} />
                  {
                    mData?.email?.split("\n").map((_elm, index) => (
                      <p key={index} className={`${modalParagraph} ${customerContactParagraph}`} >{ _elm }</p>
                    ))
                  }
                </div>
              </div>
              <div className={customerContactFeedback}>
                <RichText
                  blocks={mData.footer}
                />
              </div>
            </div>
            <div className={customerContactImage}>
              <Image className={`${customerContactImageItem} ${mobileImage}`} image={mData.mobileImage} />
              <Image className={`${customerContactImageItem} ${desktopImage}`} image={mData.desktopImage} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomerContact;
